import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';

import styles from './text.module.scss';

const Text = ({ content, icon, ...props }) => {
  if (!content || !content.childMarkdownRemark) return null;

  return (
    <div className={classNames(props.className, styles.text, {
      [styles.hasIcon]: !!icon,
    })}
    >
      <Icon icon={icon} />
      <div
        dangerouslySetInnerHTML={{
          __html: content.childMarkdownRemark.html,
        }}
      />
    </div>
  );
};

Text.propTypes = {
  icon: PropTypes.object,
  content: PropTypes.object,
};

export default Text;
