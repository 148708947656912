import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { injectIntl } from 'gatsby-plugin-intl';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import { useSiteMetadata, useProducts } from '../../hooks';
import Input from '../Input';
import Loader from '../Loader';
import PromoCode from './promo-code';

import paymentCardImage from '../../images/payment-card.png';
import moneyBackGuaranteeImage from '../../images/money-back-guarantee.jpg';
import checkoutStyles from './checkout.module.scss';
import productsStyles from './products.module.scss';

const tabs = [
  {
    index: 0,
    label: 'subscriptionTab',
  },
  {
    index: 1,
    label: 'paymentTab',
  },
];

const currencyMap = { INR: '&#8377;' };

const CheckoutForm = ({ intl, ...props }) => {
  const iframe = useRef();

  const products = useProducts();
  const { apiHost, country } = useSiteMetadata();

  const [isLoading, setLoading] = useState(false);
  const [iframeHeight, setIframeHeight] = useState('100%');
  const [tabIndex, setTabIndex] = useState(0);

  const state = {
    years: products[1].period,
    email: '',
    companyNumber: '',
    companyName: '',
    companyConcern: false,
    promoCode: '',
    firstName: '',
    lastName: '',
    lang: intl.locale.slice(0, 2),
    ip: 'n/a',
    country,
  };

  const [formData, setFormData] = useState(state);
  const [response, setResponse] = useState();
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = JSON.stringify(formData);

    fetch(`${apiHost}/lei/payment`, {
      method: 'POST',
      body,
    }).then((res) => res.json())
      .then(({ result }) => {
        setTabIndex(1);
        setResponse(result);
      });
  };

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleChecked = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.checked,
    });
  };

  const trimCompanyNumber = ({ target }) => {
    const companyNumber = target.value.replace(/-/g, '');

    handleChange({ target: { name: target.name, value: companyNumber } });
  };

  const validateOrgNumber = (value) => {
    if (value.length < 1) {
      return false;
    }

    return true;
  };

  const validateName = (value) => {
    if (value.length < 1) {
      return false;
    }

    return true;
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    async function fetchIP() {
      const re = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
      try {
        const resp = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
        const lines = await resp.text();
        lines.split('\n').forEach((line) => {
          if (line.startsWith('ip=')) {
            const ip = line.split('=').pop();
            if (re.test(ip)) {
              setFormData({ ...formData, ip });
            }
          }
        });
      } catch (err) {
        // Do nothing...
      }
    }

    fetchIP();

    return () => {};
  }, []);

  useEffect(() => {
    if (
      validateName(formData.firstName)
      && validateName(formData.lastName)
      && validateName(formData.companyName)
      && validateOrgNumber(formData.companyNumber)
      && validateEmail(formData.email)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    return () => {};
  }, [formData]);

  useEffect(() => {
    const { current } = iframe;

    if (!current) return;

    function handleIframeMessage({ origin, data }) {
      if (origin !== 'https://pay.mondido.com') return;

      const { height, loading } = JSON.parse(data);

      if (height) {
        setIframeHeight(height);
      }

      if (loading) {
        setLoading(loading);
      }
    }

    if (window.addEventListener) {
      window.addEventListener('message', handleIframeMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', handleIframeMessage);
    }
  }, [response]);

  const componentClasses = classNames(checkoutStyles.checkout, props.className);

  return (
    <div className={componentClasses}>
      <form className={checkoutStyles.form}>
        { isLoading && <Loader /> }
        <Tabs
          className={checkoutStyles.tabs}
          selectedTabClassName={checkoutStyles.isSelected}
          selectedTabPanelClassName={checkoutStyles.isSelected}
          selectedIndex={tabIndex}
          onSelect={(newTabIndex) => setTabIndex(newTabIndex)}
        >
          <TabList className={checkoutStyles.tabList}>
            {
              tabs.map(({ index, label }) => (
                <Tab
                  key={index}
                  className={checkoutStyles.tab}
                  disabledClassName={checkoutStyles.isDisabled}
                  disabled={index === 1 && tabIndex !== index}
                >
                  <span className={checkoutStyles.tabNumber}>{index + 1}</span>
                  {intl.formatMessage({ id: label })}
                </Tab>
              ))
            }
          </TabList>
          <TabPanel className={checkoutStyles.tabPanel}>
            <div className={productsStyles.products}>
              {
                products.map((product) => {
                  const productPriceYearly = Math.round(product.price / product.period);

                  return (
                    <button
                      key={product.id}
                      className={classNames(productsStyles.product, {
                        [productsStyles.isSelected]: product.period === formData.years,
                      })}
                      type="button"
                      onClick={() => setFormData({ ...formData, years: product.period })}
                    >
                      { product.description && (
                        <span className={productsStyles.productDescription}>
                          {product.description}
                        </span>
                      ) }
                      <span className={productsStyles.productName}>
                        {product.period}
                        {' '}
                        {intl.formatMessage({ id: 'yearSuffix' })}
                        {product.period > 1 && new RegExp(['en', 'EN'].join('|')).test(intl.locale) && 's'}
                      </span>
                      <span className={productsStyles.productPriceYearly}>
                        {
                          currencyMap[product.currency] && (
                            <span
                              dangerouslySetInnerHTML={{ __html: currencyMap[product.currency] }}
                            />
                          )
                        }
                        {productPriceYearly}
                        {' '}
                        {!currencyMap[product.currency] && product.currency}
                        {' '}
                        {' / '}
                        {intl.formatMessage({ id: 'yearSuffix' })}
                      </span>
                    </button>
                  );
                })
              }
            </div>

            <input type="hidden" name="years" value={formData.years} onChange={handleChange} />

            <div className={checkoutStyles.formRow}>
              <div className={checkoutStyles.formGroup}>
                <Input
                  type="text"
                  name="firstName"
                  label="fNameLabel"
                  value={formData.firstName}
                  onChange={handleChange}
                  minLength={2}
                />
              </div>

              <div className={checkoutStyles.formGroup}>
                <Input
                  type="text"
                  name="lastName"
                  label="lNameLabel"
                  value={formData.lastName}
                  onChange={handleChange}
                  minLength={2}
                />
              </div>
            </div>

            <Input
              type="email"
              name="email"
              label="emailLabel"
              value={formData.email}
              onChange={handleChange}
            />

            <div className={checkoutStyles.formRow}>
              <div className={checkoutStyles.formGroup}>
                <Input
                  type="text"
                  name="companyNumber"
                  label="companyNumberLabel"
                  value={formData.companyNumber}
                  onChange={handleChange}
                  onBlur={trimCompanyNumber}
                  maxLength={11}
                />
                { country && (country === 'in' || country === 'au') && <span>{intl.formatMessage({ id: 'companyNumberHelp' })}</span> }
              </div>

              <div className={checkoutStyles.formGroup}>
                <Input
                  type="text"
                  name="companyName"
                  label="companyNameLabel"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>

            { country === 'se' ? (
              <PromoCode
                value={formData.promoCode}
                onChange={handleChange}
              />
            ) : (
              <input type="hidden" name="promoCode" value="" />
            )}

            <Input
              type="checkbox"
              name="companyConcern"
              label="companyConcernLabel"
              onChange={handleChecked}
            />

            <button
              className={checkoutStyles.button}
              type="submit"
              onClick={handleSubmit}
              disabled={!isValid}
            >
              { intl.formatMessage({ id: 'continueToPaymentBtn' })}
            </button>
            <p className={checkoutStyles.priceDetails}>
              <small>{ intl.formatMessage({ id: 'allPricesExcludeVat' })}</small>
            </p>
          </TabPanel>

          <TabPanel className={checkoutStyles.tabPanel}>
            { response && response.href && (
              <iframe
                ref={iframe}
                className={checkoutStyles.iframe}
                src={response.href}
                title={response.paymentId}
                width="100%"
                height={iframeHeight}
                frameBorder={0}
                onLoad={() => setLoading(false)}
              />
            ) }
          </TabPanel>
        </Tabs>

        <div className={checkoutStyles.paymentLogos}>
          <img className={checkoutStyles.paymentCardImage} src={paymentCardImage} alt="Pay safe with VISA and Mastercard" />
          <img className={checkoutStyles.moneyBackGuaranteeImage} src={moneyBackGuaranteeImage} alt="100% money back guarantee" />
        </div>
      </form>
    </div>
  );
};

export default injectIntl(CheckoutForm);
