import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';

import styles from './input.module.scss';

const Input = forwardRef(({
  name,
  type,
  value,
  label,
  ...props
}, ref) => {
  const refInput = ref != null ? ref : useRef();
  const intl = useIntl();

  const [isFocus, setFocus] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const isText = (type === 'text' || type === 'email' || type === 'search' || type === 'tel' || type === 'url' || type === 'number');

  const componentClasses = classNames(styles.inputGroup, props.className, {
    [styles.isFocus]: !!isFocus,
    [styles.hasValue]: !!hasValue,
    [styles.isText]: isText,
    [styles.isCheckbox]: type === 'checkbox',
  });

  useEffect(() => {
    const { current } = refInput;

    if (current.value) {
      setHasValue(true);
    }

    const handleFocus = () => {
      setFocus(true);
    };

    const handleBlur = () => {
      setFocus(false);
    };

    const handleHasChanged = () => {
      setHasValue(current.value);
    };

    current.addEventListener('focus', handleFocus);
    current.addEventListener('blur', handleBlur);
    current.addEventListener('change', handleHasChanged);

    return () => {
      current.removeEventListener('focus', handleFocus);
      current.removeEventListener('blur', handleBlur);
      current.removeEventListener('change', handleHasChanged);
    };
  }, []);

  return (
    <div className={componentClasses}>
      { label && type !== 'checkbox' && (
        <label htmlFor={name} className={styles.label}>
          {intl.formatMessage({ id: label })}
        </label>
      ) }
      <input
        className={styles.input}
        ref={refInput}
        type={type}
        id={name}
        name={name}
        value={value}
        maxLength={props.maxLength}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      { label && type === 'checkbox' && (
        <label htmlFor={name} className={styles.label}>
          {intl.formatMessage({ id: label })}
        </label>
      ) }
    </div>
  );
});

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default Input;
