import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Block from '../Block';
import Text from '../Text';

import styles from './article-base.module.scss';

const ArticleBase = ({
  name, content, blocks, ...props
}) => (
  <article className={classNames(props.className, styles.article)}>
    {blocks ? blocks.map((block) => (
      <Block key={block.id} block={block} {...props} />
    )) : (
      <div className={styles.container}>
        { name && (
          <header className={styles.header}>
            <h1 className={styles.title}>{name}</h1>
          </header>
        )}
        <Text content={content} className={styles.content} />
      </div>
    )}
  </article>
);

ArticleBase.propTypes = {
  name: PropTypes.string,
  content: PropTypes.object,
  blocks: PropTypes.array,
};

export default ArticleBase;
