import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'gatsby-image';

import styles from './image.module.scss';

const Image = ({
  image, title, ...props
}) => (
  <Img
    className={classNames(props.className, styles.image)}
    fluid={image.fluid}
    fixed={image.fixed}
    alt={title}
    imgStyle={props.imgStyle}
    objectFit={props.objectFit}
    objectPosition={props.objectPosition}
    fadeIn={props.fadeIn}
  />
);

Image.defaultProps = {
  objectFit: 'contain',
  objectPosition: '50% 50%',
};

Image.propTypes = {
  image: PropTypes.object.isRequired,
  alt: PropTypes.string,
  imgStyle: PropTypes.object,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  fadeIn: PropTypes.bool,
};

export default Image;
