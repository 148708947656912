import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';

import styles from './counter.module.scss';

const DAYS_MULTIPLIER = 60 * 60 * 24;
const HOURS_MULTIPLIER = 60 * 60;
const MINUTES_MULTIPLIER = 60;

function getTimeStart() {
  const start = new Date();

  start.setHours(9, 0, 0);

  return start.toString();
}

function getTimeEnd() {
  const end = new Date();

  end.setHours(18, 0, 0);

  return end.toString();
}

const Counter = ({
  startDate, endDate, title, useLeadingZero, isBlock, ...props
}) => {
  const intl = useIntl();

  const [active, setActive] = useState(false);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  function formatCountdown(str) {
    return useLeadingZero ? (`0${str}`).slice(-2) : str;
  }

  function calculate({ startDate: start, endDate: end }) {
    const timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    const now = new Date();
    const timeNow = Date.parse(now);

    if (start) {
      const timeStart = Date.parse(start || getTimeStart());
      const startTime = (timeStart - timeNow) / 1000;

      if (startTime >= 0) return false;
    }

    const timeEnd = Date.parse(end || getTimeEnd());

    let endTime = (timeEnd - timeNow) / 1000;

    if (endTime < 0) return false;

    if (endTime >= DAYS_MULTIPLIER) {
      timeLeft.days = Math.floor(endTime / DAYS_MULTIPLIER);
      endTime -= timeLeft.days * DAYS_MULTIPLIER;
    }

    if (endTime >= HOURS_MULTIPLIER) {
      timeLeft.hours = Math.floor(endTime / HOURS_MULTIPLIER);
      endTime -= timeLeft.hours * HOURS_MULTIPLIER;
    }

    if (endTime >= MINUTES_MULTIPLIER) {
      timeLeft.minutes = Math.floor(endTime / MINUTES_MULTIPLIER);
      endTime -= timeLeft.minutes * MINUTES_MULTIPLIER;
    }

    timeLeft.seconds = endTime;

    return timeLeft;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const counter = calculate({ startDate, endDate });

      if (counter) {
        setActive(true);
        setCountdown(counter);
      } else {
        setActive(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const componentClasses = classNames(styles.counter, props.className, {
    [styles.isActive]: !!active,
    [styles.isBlock]: isBlock,
  });

  if (!active) return null;

  return (
    <div className={componentClasses}>
      { title && <h2 className={styles.title}>{title}</h2> }
      <div className={styles.container}>
        { countdown.days > 0 && (
          <div className={classNames(styles.column, styles.days)}>
            <span className={styles.number}>{formatCountdown(countdown.days)}</span>
            <span className={styles.label}>{intl.formatMessage({ id: 'days' })}</span>
          </div>
        ) }
        <div className={classNames(styles.column, styles.hours)}>
          <span className={styles.number}>{formatCountdown(countdown.hours)}</span>
          <span className={styles.label}>{intl.formatMessage({ id: 'hours' })}</span>
        </div>
        <div className={classNames(styles.column, styles.minutes)}>
          <span className={styles.number}>{formatCountdown(countdown.minutes)}</span>
          <span className={styles.label}>{intl.formatMessage({ id: 'minutes' })}</span>
        </div>
        <div className={classNames(styles.column, styles.seconds)}>
          <span className={styles.number}>{formatCountdown(countdown.seconds)}</span>
          <span className={styles.label}>{intl.formatMessage({ id: 'seconds' })}</span>
        </div>
      </div>
    </div>
  );
};

Counter.defaultProps = {
  useLeadingZero: true,
  isBlock: false,
};

Counter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  title: PropTypes.string,
  useLeadingZero: PropTypes.bool,
  isBlock: PropTypes.bool,
};

export default Counter;
