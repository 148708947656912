import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlockWrapper from '../BlockWrapper';
import Counter from '../Counter';
import Image from '../Image';
import Text from '../Text';
import Checkout from '../Checkout';

import styles from './checkout-block.module.scss';

const CheckoutBlock = ({
  __typename,
  title,
  content,
  image,
  showCountdown,
  countdownTitle,
  countdownStartDate,
  countdownEndDate,
}) => (
  <BlockWrapper className={classNames(__typename, styles.section)}>
    {title && (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <Text content={content} className={styles.text} />
          { showCountdown && (
            <Counter
              className={styles.counter}
              title={countdownTitle}
              startDate={countdownStartDate}
              endDate={countdownEndDate}
            />
          ) }
        </div>
        <Checkout className={styles.checkout} fadeIn />
      </div>
    )}
    {image && (
      <Image
        image={image}
        className={styles.image}
        objectFit="cover"
        height="100%"
        imgStyle={{ objectPosition: 'bottom center' }}
      />
    )}
  </BlockWrapper>
);

CheckoutBlock.propTypes = {
  __typename: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
};

export default CheckoutBlock;
