import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlockWrapper from '../BlockWrapper';
import Counter from '../Counter';

import styles from './countdown-block.module.scss';

const CountdownBlock = ({
  __typename, title, startDate, endDate, ...props
}) => (
  <BlockWrapper className={classNames(__typename, styles.section)} {...props}>
    {title && (
      <div className={styles.container}>
        <Counter
          className={styles.counter}
          title={title}
          startDate={startDate}
          endDate={endDate}
          isBlock
        />
      </div>
    )}
  </BlockWrapper>
);

CountdownBlock.propTypes = {
  __typename: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
};

export default CountdownBlock;
