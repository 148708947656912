import React from 'react';
import styles from './loader.module.scss';

const Loader = () => (
  <div className={styles.loaderOverlay}>
    <div className={styles.spinner}>
      <div className={styles.spinnerItem} />
      <div className={styles.spinnerItem} />
      <div className={styles.spinnerItem} />
    </div>
  </div>
);

export default Loader;
