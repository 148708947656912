import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlockWrapper from '../BlockWrapper';
import Image from '../Image';
import Text from '../Text';

import styles from './hero-block.module.scss';

const HeroBlock = ({
  __typename, title, content, image,
}) => (
  <BlockWrapper className={classNames(__typename, styles.section)}>
    {title && (
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <Text content={content} className={styles.text} />
        </div>
      </div>
    )}
    {image.fluid && (
      <Image
        image={image}
        className={styles.image}
        height="100%"
        objectFit="cover"
      />
    )}
  </BlockWrapper>
);

HeroBlock.propTypes = {
  __typename: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
};

export default HeroBlock;
