import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from '../Image';

import styles from './testimonial.module.scss';

const Testimonial = ({
  name, content, image, ...props
}) => {
  if (!content) return null;

  return (
    <div className={classNames(props.className, styles.testimonial)}>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: content.childMarkdownRemark.html,
        }}
      />
      <Image image={image} className={styles.image} />
      <p className={styles.name}>{name}</p>
    </div>
  );
};

Testimonial.propTypes = {
  content: PropTypes.object,
  image: PropTypes.object,
};

export default Testimonial;
