import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Input from '../Input';
import styles from './promo-code.module.scss';

const PromoCode = ({ value, onChange }) => {
  const inputRef = useRef();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const focusInput = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    if (open) {
      focusInput();
    } else {
      // reset parent value
      onChange({ target: { name: 'promoCode', value: '' } });
    }
  }, [open]);

  return (
    <div className={styles.promoCode}>
      <button
        type="button"
        onClick={toggle}
        aria-pressed={open}
        className={styles.btn}
      >
        {intl.formatMessage({ id: 'promoCodeToggleLabel' })}
      </button>
      { open ? (
        <Input
          type="text"
          name="promoCode"
          label="promoCodeLabel"
          value={value}
          onChange={onChange}
          ref={inputRef}
          className={styles.field}
        />
      ) : (
        <input type="hidden" name="promoCode" value={value} />
      )}
    </div>
  );
};

export default PromoCode;
