import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './icon.module.scss';

const Icon = ({ icon, ...props }) => {
  if (!icon || !icon.file) return null;

  return (
    <div className={classNames(props.className, styles.icon)}>
      <img src={icon.file.url} alt={icon.title} />
    </div>
  );
};

Icon.propTypes = {
  icon: PropTypes.object,
};

export default Icon;
